import styled, { css } from 'styled-components';
import { shakeKeyframes } from '../../../styles/keyframes';

interface IInsertPasswordMessageProps {
  animation: boolean;
}

export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 1rem;
`;

export const InsertPasswordMessage = styled.p<IInsertPasswordMessageProps>`
  margin-bottom: 1.5rem;

  ${props =>
    props.animation &&
    css`
      animation: ${shakeKeyframes} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97)
        both;
      transform: translate3d(0, 0, 0);
    `}
`;

export const LoadingWrapper = styled.div`
  > div {
    height: 270px;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2.25rem;
  justify-content: flex-end;
`;

export const Title = styled.h5`
  margin: 0;
  font-family: Roboto, Barlow;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 2.25rem;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;
