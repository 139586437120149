import { CrudContext } from 'devyx-ui';
import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ModalFirstAccess from '../components/layout/ModalFirstAccess';
import ModalNoCompany from '../components/layout/ModalNoCompany';
import PrivateRoute from './PrivateRoute';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const Profile = lazy(() => import('../pages/Profile'));
const Notifications = lazy(() => import('../pages/Notifications'));
const UsersRoutes = lazy(() => import('../modules/Users/Users.routes'));
const CompaniesRoutes = lazy(
  () => import('../modules/Companies/Companies.routes'),
);
const SubscriptionsRoutes = lazy(
  () => import('../modules/Subscriptions/Subscriptions.routes'),
);
const HttpAndStructureProvider = lazy(
  () => import('../hooks/HttpAndStructureContext'),
);
const ForbiddenPage = lazy(() => import('../pages/Forbidden'));
const ReportsRoutes = lazy(() => import('../modules/Reports/Reports.routes'));

export const PrivateRoutes = () => {
  const { setOpenAddCrudModal } = CrudContext.useCrud();

  return (
    <HttpAndStructureProvider>
      <div
        style={{
          maxWidth: '1120px',
          margin: '160px auto 5rem',
        }}
      >
        <PrivateRoute path="/">
          <Switch>
            <PrivateRoute path="/" exact component={Dashboard} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/notifications" component={Notifications} />
            <PrivateRoute path="/reports" component={ReportsRoutes} />
            <PrivateRoute
              path="/subscriptions"
              component={SubscriptionsRoutes}
            />

            <PrivateRoute path="/users">
              <UsersRoutes setOpenAddCrudModal={setOpenAddCrudModal} />
            </PrivateRoute>
            <PrivateRoute path="/companies" component={CompaniesRoutes} />
            <PrivateRoute path="/error/forbidden" component={ForbiddenPage} />
            <Route>
              <Redirect to="/404" />
            </Route>
          </Switch>
        </PrivateRoute>

        <ModalNoCompany />
        <ModalFirstAccess />
      </div>
    </HttpAndStructureProvider>
  );
};

export default PrivateRoutes;
