import React from 'react';
import { LoadingGeneral } from 'devyx-ui';
import { Container } from './styles';

const SplashScreen: React.FC = () => (
  <Container>
    <LoadingGeneral height={500} />
  </Container>
);

export default SplashScreen;
