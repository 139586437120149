import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

import Login from '../pages/Login';
import Logout from '../pages/Logout';
import PrivateRoute from './PrivateRoute';
import PrivateRoutes from './PrivateRoutes';

const NotFoundPage = lazy(() => import('../pages/NotFound'));
const RecoverPasswordPage = lazy(() => import('../pages/RecoverPassword'));
const UpdateEmailPage = lazy(() => import('../pages/UpdateEmail'));
const PDFFrame = lazy(() => import('../components/frame/PDFFrame'));
const CompanyInvitation = lazy(() => import('../pages/CompanyInvitation'));

const Routes: React.FC = () => {
  const {
    keycloak: { authenticated },
  } = useKeycloak();

  return (
    <Switch>
      {!authenticated && <PrivateRoute path="/" exact />}
      <Route path="/404" component={NotFoundPage} />
      <Route path="/login" component={Login} />
      <Route path="/terms">
        <PDFFrame
          url={`${process.env.REACT_APP_BASE_AMAZON_URL}/termos_de_uso.pdf`}
          title="Termos de uso"
        />
      </Route>
      <Route path="/privacy">
        <PDFFrame
          url={`${process.env.REACT_APP_BASE_AMAZON_URL}/politicas_de_privacidade.pdf`}
          title="Políticas de privacidade"
        />
      </Route>
      <Route path="/data-processing">
        <PDFFrame
          url={`${process.env.REACT_APP_BASE_AMAZON_URL}/tratamento_e_processamento_de_dados.pdf`}
          title="Tratamento de dados"
        />
      </Route>
      <Route path="/recovery/:data?" component={RecoverPasswordPage} />
      <Route path="/change-email/:token?" component={UpdateEmailPage} />
      <PrivateRoute path="/logout" component={Logout} />
      <PrivateRoute path="/invite-user/:data" component={CompanyInvitation} />

      {authenticated ? (
        <PrivateRoutes />
      ) : (
        <Route>
          <Redirect to="/404" />
        </Route>
      )}
    </Switch>
  );
};

export default Routes;
