import { useKeycloak } from '@react-keycloak/web';
import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import useFetch, { CachePolicies, IncomingOptions } from 'use-http';
import SplashScreen from '../components/layout/SplashScreen';
import { userLevels } from '../helpers/auth';

interface IAuthProviderProps {
  children: ReactNode;
}

// TODO: passar essas interfaces para o arquivo @types/auth.ts

interface ICompany {
  id: string;
  document: string;
  name: string;
  email: string;
  phone_number: string;
  avatar: boolean;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

interface ICategory {
  id: number;
  description: 'master' | 'admin' | 'member';
}

interface ILevel {
  description: keyof typeof userLevels;
  id: number;
}

export interface ICompanyCategory {
  id: string;
  name: string;
  avatar: boolean;
  updated_at: string;
  is_active: boolean;
  category: ICategory;
}

interface ICategoryCompanyLevelUser extends ICategoryCompanyLevelUserRelations {
  id: string;
  user_id: string;
  level_id: number;
  company_id?: string | null;
  category_id?: number | null;
  level: ILevel;
}

interface ICategoryCompanyLevelUserRelations {
  company?: ICompany | null;
  category?: ICategory | null;
  // user: IUser;
  // level: ILevel;
}

interface IAuthResponseData {
  id: string;
  api_key: string;
  name: string;
  email: string;
  phone_number: string;
  category_company_level_user: ICategoryCompanyLevelUser[];
  avatar: boolean;
  is_active: boolean;
  marketing: string; // JSON string
  notifications: string; // JSON string
  updated_at: string;
  created_at: string;
}

// parsed JSON string
interface INotifications {
  zoug?: boolean;
  trader?: boolean;
  buildr?: boolean;
}

// parsed JSON string
interface IMarketing {
  zoug?: boolean;
  trader?: boolean;
  buildr?: boolean;
}

export interface IAuthData
  extends Omit<
    IAuthResponseData,
    'category_company_level_user' | 'marketing' | 'notifications'
  > {
  companySelected?: ICompanyCategory;
  companiesToSelect?: ICompanyCategory[];
  partnerCompanies?: ICompanyCategory[];
  notifications: INotifications;
  marketing: IMarketing;
  keycloackId?: string;
  categoryCompanyLevelUser: ICategoryCompanyLevelUser[];
  isPartner: boolean;
  requires_password_change: boolean;
}

interface IAuthContextData {
  authData: IAuthData;
  updateAuthData: () => Promise<void>;
  modalNoCompanyStatus: boolean;
  setModalNoCompanyStatus: Dispatch<SetStateAction<boolean>>;
  modalFirstAccessStatus: boolean;
  setModalFirstAccessStatus: Dispatch<SetStateAction<boolean>>;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider = ({
  children,
}: IAuthProviderProps): ReactElement => {
  const [authData, setAuthData] = useState<IAuthData>({} as IAuthData);
  const [modalNoCompanyStatus, setModalNoCompanyStatus] = useState(false);
  const [modalFirstAccessStatus, setModalFirstAccessStatus] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    keycloak: { tokenParsed, authenticated, token },
  } = useKeycloak();

  const { get, response } = useFetch(process.env.REACT_APP_BACKEND_URL, {
    cachePolicy: CachePolicies.CACHE_AND_NETWORK,
    suspense: true,
    timeout: 3000,
    interceptors: {
      request: async ({ options }) => {
        if (authenticated) {
          options.headers = {
            authorization: `Bearer ${token}`,
          };
        }
        return options;
      },
    },
  } as IncomingOptions);

  const updateAuthData = useCallback(async () => {
    setLoading(true);
    await get('/auth/users/me');

    if (response.ok) {
      const {
        category_company_level_user,
        marketing,
        notifications,
        ...newAuthData
      } = response.data as IAuthResponseData;

      // ----------------------------------------------------------------------
      // carrega as informações sobre notificações e marketing
      // ----------------------------------------------------------------------

      const notificationsParsed = {
        zoug: false,
        buildr: false,
        trader: false,
      } as INotifications;

      const marketingParsed = {
        zoug: false,
        buildr: false,
        trader: false,
      } as IMarketing;

      try {
        const newNotificationsParsed = JSON.parse(notifications);
        const newMarketingParsed = JSON.parse(marketing);
        Object.assign(notificationsParsed, newNotificationsParsed);
        Object.assign(marketingParsed, newMarketingParsed);
      } catch (error) {
        setLoading(false);
        if (error instanceof SyntaxError) {
          console.warn(notifications, error.name, error.message);
        }
      }

      // ----------------------------------------------------------------------
      // carrega as informações sobre as companhias
      // ----------------------------------------------------------------------

      // sempre haverá um registro em category_company_level_user onde as informações
      // sobre company e a category (e suas ids) são nulas, por isso o FILTER
      const filtered = category_company_level_user.filter(
        item => item.category_id !== null && item.company_id !== null,
      );
      const companiesToSelect = filtered
        .filter(item => item.level.description !== userLevels.partner)
        .map(
          item =>
            ({
              id: item.company_id || '',
              name: item.company?.name || '',
              avatar: item.company?.avatar || false,
              updated_at: item.company?.updated_at || '',
              is_active: item.company?.is_active || false,
              category: item.category || {},
            } as ICompanyCategory),
        );

      // a companhia selecionada pode ser escolhida de duas formas:
      // caso Nº 1 - caso já tenha sido selecionada anteriormente e esta escolha
      //     estiver salva no localStorage, a companhia armazenada no localStorage
      //     é selecionada e colocada no contexto;
      // caso Nº 2 - caso não tenha nada no localStorage, é selecionada a primeira
      //     companhia encontrada no registro de companhias deste usuário.
      let companySelected = {
        id: '',
        name: '',
        avatar: false,
        updated_at: '',
        is_active: false,
        category: {},
      } as ICompanyCategory;

      const thereIsALocalStorage = localStorage.getItem(
        '@DevyxAccount:company_id',
      );
      // caso Nº 1 -- id da companhia presente no localStorage
      if (thereIsALocalStorage) {
        const isValidCompanyId = companiesToSelect.some(
          item => item.id === thereIsALocalStorage,
        );

        // se a companhia no localStorage está vinculada ao usuário logado,
        // então aproveita ela e coloca no contexto
        if (isValidCompanyId) {
          companySelected =
            companiesToSelect.find(item => item?.id === thereIsALocalStorage) ||
            companySelected;
        }
        // caso contrário, a mesma lógica do caso Nº 2 é aplicada
        else {
          localStorage.setItem(
            '@DevyxAccount:company_id',
            companiesToSelect.at(0)?.id || '',
          );
          // seleciona a primeira companhia ou nenhuma
          companySelected = companiesToSelect.at(0) || companySelected;
        }
      }
      // caso Nº 2 -- nenhuma OU a primeira companhia encontrada é selecionada
      else {
        localStorage.setItem(
          '@DevyxAccount:company_id',
          companiesToSelect.at(0)?.id || '',
        );
        // seleciona a primeira companhia ou nenhuma
        companySelected = companiesToSelect.at(0) || companySelected;
      }

      setAuthData({
        notifications: notificationsParsed,
        marketing: marketingParsed,
        companiesToSelect,
        companySelected,
        keycloackId: tokenParsed?.sub,
        categoryCompanyLevelUser: category_company_level_user,
        isPartner: category_company_level_user.some(
          item => item.level.description === userLevels.partner,
        ),
        ...newAuthData,
      } as IAuthData);
    } else {
      // response.ok === false
      // TODO: caso o Auth retorne erro, definir oq será mostrado no front
      toast.error('Serviço indisponível! Tente novamente mais tarde.');
    }
    setLoading(false);
  }, [get, response, tokenParsed?.sub]);

  useEffect(() => {
    // foi colocado a condição pois na tela de cadastrar estava fazendo a req
    // na /me e dando 500 pois não havia usuário logado
    if (authenticated) {
      updateAuthData();
    }
  }, [authenticated, updateAuthData]);

  const memoizedContextValue = useMemo<IAuthContextData>(
    () => ({
      authData,
      updateAuthData,
      modalNoCompanyStatus,
      setModalNoCompanyStatus,
      modalFirstAccessStatus,
      setModalFirstAccessStatus,
    }),
    [authData, modalNoCompanyStatus, updateAuthData, modalFirstAccessStatus],
  );

  return !loading ? (
    <AuthContext.Provider value={memoizedContextValue}>
      {children}
    </AuthContext.Provider>
  ) : (
    <SplashScreen />
  );
};

export const useAuth = (): IAuthContextData => {
  return useContext(AuthContext);
};

export default AuthProvider;
