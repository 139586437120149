import * as React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Redirect } from 'react-router-dom';
import { useRedirectTo } from '../helpers/location';
import SplashScreen from '../components/layout/SplashScreen';

const Login: React.FC = () => {
  const { keycloak } = useKeycloak();
  const currentLocationState = useRedirectTo();
  if (keycloak?.authenticated) {
    return <Redirect to={currentLocationState?.from} />;
  }

  if (currentLocationState?.from.pathname === '/logout') {
    currentLocationState.from.pathname = '/';
  }

  keycloak?.login({
    redirectUri: `${window.location.origin}${currentLocationState?.from.pathname}`,
  });

  return <SplashScreen />;
};

export default Login;
