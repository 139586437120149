import React, { useEffect } from 'react';
import Keycloak from 'keycloak-js';
import { AuthClientInitOptions } from '@react-keycloak/core';
import { useKeycloak } from '@react-keycloak/web';
import ConfigKeycloak from '../keycloak.json';
import SplashScreen from '../components/layout/SplashScreen';

interface IIsKeycloakInitialized {
  children?: any;
}

export const keycloakInstance = new Keycloak({
  url: process.env.REACT_APP_SSO_URL ?? ConfigKeycloak['auth-server-url'],
  realm: ConfigKeycloak.realm,
  clientId: ConfigKeycloak.resource,
});

export const keycloakConfig: AuthClientInitOptions = {
  onLoad: 'check-sso',
};

export const IsKeycloakInitialized: React.FC = ({
  children,
}: IIsKeycloakInitialized) => {
  const { initialized, keycloak } = useKeycloak();
  useEffect(() => {
    if (initialized && keycloak) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600);
    }

    return () => {
      if (keycloak) keycloak.onTokenExpired = () => null;
    };
  }, [keycloak, initialized]);
  return !initialized ? <SplashScreen /> : children;
};

IsKeycloakInitialized.defaultProps = {
  children: null,
};

export const userCategories = {
  master: 'master',
  admin: 'admin',
  member: 'member',
  partner: 'partner',
};

export const userLevels = {
  system: 'system',
  client: 'client',
  partner: 'partner',
  vendor: 'vendor',
};
