import { Button, Input, LoadingGeneral, Modal, Styles } from 'devyx-ui';
import { FaSave } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import useFetch from 'use-http';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/AuthContext';
import {
  FooterContainer,
  InsertPasswordMessage,
  LoadingWrapper,
  PasswordContainer,
  Title,
} from './styles';

export const ModalFirstAccess = (): JSX.Element => {
  const [userChangedHisPassword, setUserChangedHisPassword] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('Seja bem vindo.');

  const { put, response, loading } = useFetch();
  const { modalFirstAccessStatus, setModalFirstAccessStatus, authData } =
    useAuth();

  const handleSubmitNewPassword = useCallback(
    async values => {
      await put(`/auth/users/change-password`, values);
      if (response.ok) {
        toast.success('Senha atualizada com sucesso!');
      } else {
        toast.error(
          'Ocorreu um erro interno. Tente alterar sua senha mais tarde!',
        );
      }
      setModalFirstAccessStatus(false);
      setUserChangedHisPassword(true);
    },
    [put, response.ok, setModalFirstAccessStatus],
  );

  const changePasswordForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required('Senha é obrigatória')
        .min(8, 'Mínimo 8 caracteres'),
      password_confirmation: Yup.string()
        .required('Confirmação de senha é obrigatória')
        .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
    }),
    onSubmit: async values => {
      await handleSubmitNewPassword(values);
    },
  });

  const handleClose = useCallback(() => {
    if (userChangedHisPassword) {
      setModalFirstAccessStatus(false);
    } else {
      setAnimation(true);
    }
  }, [userChangedHisPassword, setModalFirstAccessStatus]);

  useEffect(() => {
    if (authData.requires_password_change) {
      if (authData.name) {
        const firstName = authData.name.split(' ').at(0) ?? '';
        if (firstName?.length > 0)
          setWelcomeMessage(`Seja bem vindo, ${firstName}.`);
      }
      setModalFirstAccessStatus(true);
    }
  }, [
    authData.requires_password_change,
    authData.name,
    setModalFirstAccessStatus,
  ]);

  return (
    <Modal.ModalBase
      isOpen={modalFirstAccessStatus}
      setIsOpen={handleClose}
      width="450px"
    >
      <Title>{welcomeMessage}</Title>
      {!loading ? (
        <>
          <p>
            Este é seu primeiro acesso.
            <br />
          </p>
          <InsertPasswordMessage
            animation={animation}
            onAnimationEnd={() => {
              setAnimation(false);
            }}
          >
            Para continuar, preencha uma nova senha:
          </InsertPasswordMessage>
          <Styles.Form onSubmit={changePasswordForm.handleSubmit}>
            <PasswordContainer>
              <Input
                name="password"
                label="Senha"
                type="password"
                placeholder="Digite a nova senha"
                formAttributes={changePasswordForm}
              />
              <Input
                name="password_confirmation"
                label="Confirmar senha"
                type="password"
                placeholder="Digite novamente a senha"
                formAttributes={changePasswordForm}
              />
            </PasswordContainer>

            {!loading && (
              <FooterContainer>
                <Button type="submit">
                  <FaSave /> Salvar
                </Button>
              </FooterContainer>
            )}
          </Styles.Form>
        </>
      ) : (
        <LoadingWrapper>
          <LoadingGeneral />
        </LoadingWrapper>
      )}
    </Modal.ModalBase>
  );
};

export default ModalFirstAccess;
