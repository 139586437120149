import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CrudContext } from 'devyx-ui';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Routes from './routes';
import {
  IsKeycloakInitialized,
  keycloakInstance,
  keycloakConfig,
} from './helpers/auth';
import SplashScreen from './components/layout/SplashScreen';
import { AuthProvider } from './hooks/AuthContext';
import { StyledToastContainer } from './components/layout/StyledToastContainer';

import GlobalStyle from './styles/global';

const App: React.FC = () => (
  <Suspense fallback={<SplashScreen />}>
    <StyledToastContainer pauseOnHover position="bottom-right" />
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      autoRefreshToken
      initOptions={keycloakConfig}
    >
      <IsKeycloakInitialized>
        <BrowserRouter>
          <CrudContext.CrudProvider>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </CrudContext.CrudProvider>
        </BrowserRouter>
        <GlobalStyle />
      </IsKeycloakInitialized>
    </ReactKeycloakProvider>
  </Suspense>
);

export default App;
