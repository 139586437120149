import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.2rem 1rem;

  svg {
    color: var(--yellow);
    margin-bottom: 3%;
  }

  span {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;
  }

  a {
    &:first-child {
      align-items: center;
    }

    margin-top: 2rem;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    color: var(--blue);
    font-size: 1.1rem;

    svg {
      color: var(--blue);
      margin-right: 4px;
      width: 1.3rem;
      height: 1.3rem;
    }
  }
`;
