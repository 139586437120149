import React, { useCallback } from 'react';
import { ModalAlert } from 'devyx-ui';
import { Link, useHistory } from 'react-router-dom';
import { IoWarningOutline } from 'react-icons/io5';
import { IoMdPersonAdd } from 'react-icons/io';
import { TiPencil } from 'react-icons/ti';
import { useAuth } from '../../../hooks/AuthContext';
import { Container } from './styles';

const ModalNoCompany: React.FC = () => {
  const { push } = useHistory();
  const { setModalNoCompanyStatus, modalNoCompanyStatus } = useAuth();

  const handleClose = useCallback(() => {
    setModalNoCompanyStatus(false);
    push('/');
  }, [push, setModalNoCompanyStatus]);

  return (
    <ModalAlert
      isOpen={modalNoCompanyStatus}
      handleClose={handleClose}
      align="center"
      hideFooter
    >
      <Container>
        <IoWarningOutline size={80} />
        <span>
          Percebemos que você não está vinculado à nenhuma companhia. Para uso
          dos nossos serviços, isto é necessário.
        </span>
        <Link to="/" onClick={() => setModalNoCompanyStatus(false)}>
          <IoMdPersonAdd /> Aguardar um convite
        </Link>
        <Link to="/companies" onClick={() => setModalNoCompanyStatus(false)}>
          <TiPencil /> Cadastrar uma companhia agora
        </Link>
      </Container>
    </ModalAlert>
  );
};

export default ModalNoCompany;
